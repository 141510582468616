import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function JobCardFront(props) {
  const { company, position, logo, period } = props;
  return (
    // Wrapped jobcards in divs because otherwise the flip animation was messed up
    <div>
      <Card className="m-4 text-center" bg="light" text="dark">
        <Card.Header>
          <Card.Title>
            {position}
            <FontAwesomeIcon
              className="position-absolute top-0 start-0 p-2"
              icon="fa-solid fa-arrow-right-long"
              size="sm"
              flip="horizontal"
            />
            <FontAwesomeIcon
              className="position-absolute top-0 end-0 p-2"
              icon="fa-solid fa-arrow-right-long"
              size="sm"
            />
          </Card.Title>
          <Card.Subtitle className="text-center mb-2 text-muted">
            {company}
          </Card.Subtitle>
        </Card.Header>
        <Card.Body>
          <Card.Img
            src={logo}
            alt={`${company} logo`}
            className="border border-dark border-5 rounded-circle"
          />
        </Card.Body>
        <Card.Footer>{period}</Card.Footer>
      </Card>
    </div>
  );
}
