import coupangLogo from "@/assets/jobs/coupangLogo.png";
import eecsLogo from "@/assets/jobs/eecsLogo.png";
import michiganMedicineLogo from "@/assets/jobs/michiganMedicineLogo.png";

// TODO: consolidate front and back into one datastructure
const jobList = [
  {
    company: "Coupang Inc",
    position: "Software Development Intern",
    logo: coupangLogo,
    period: "May 2022 - August 2022",
    techstack: [
      {
        icon: "fa-brands fa-react",
        description: "React JS",
      },
      {
        icon: "fa-brands fa-vuejs",
        description: "Vue JS",
      },
      {
        icon: "fa-brands fa-java",
        description: "Java (Spring)",
      },
      {
        icon: "fa-brands fa-jenkins",
        description: "Jenkins",
      },
      {
        icon: "fa-brands fa-aws",
        description: "AWS DynamoDB + S3",
      },
      {
        icon: "fa-brands fa-css3-alt",
        description: "CSS (Bootstrap)",
      },
    ],
  },
  {
    company: "University of Michigan",
    position: "Instructional Aide",
    logo: eecsLogo,
    period: "January 2022 - Present",
    techstack: [
      {
        icon: "fa-brands fa-js",
        description: "Javascript (ES6 + React)",
      },
      {
        icon: "fa-brands fa-html5",
        description: "HTML",
      },
      {
        icon: "fa-database",
        description: "SQL",
      },
    ],
  },
  {
    company: "Michigan Center for Translational Pathology",
    position: "Research Assistant",
    logo: michiganMedicineLogo,
    period: "September 2020 - January 2022",
    techstack: [
      {
        icon: "fa-brands fa-python",
        description: "Python",
      },
      {
        icon: "fa-terminal",
        description: "Bash Scripting + Linux",
      },
      {
        icon: "fa-brands fa-r-project",
        description: "R (Bioconductor)",
      },
    ],
  },
];

export default jobList;
