import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";

export default function JobCardRow(props) {
  const { title, icons } = props;

  const showDescription = (props, description) => (
    <Tooltip {...props}>{description}</Tooltip>
  );

  return (
    <span>
      <h2 className="p-2 mb-0">{title}</h2>
      {icons.map((icon) => (
        <Col className="d-inline-block m-1" key={icon.description}>
          <OverlayTrigger
            placement="top"
            overlay={(hoverProps) =>
              showDescription(hoverProps, icon.description)
            }
          >
            <FontAwesomeIcon
              icon={`fa-solid ${icon.icon}`}
              // TODO: don't hardcode width & height
              style={{ width: "25px", height: "25px" }}
              size="2xl"
              className="border border-dark border-3 rounded-4 p-2 m-0"
            />
          </OverlayTrigger>
        </Col>
      ))}
    </span>
  );
}
