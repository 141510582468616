import ReactCardFlip from "react-card-flip";
import JobCardFront from "./JobCardFront";
import JobCardBack from "./JobCardBack";
import { useState } from "react";
import Col from "react-bootstrap/Col";

export default function Job(props) {
  const [flipped, setFlipped] = useState(false);
  const flipCard = () => setFlipped((prevFlipped) => !prevFlipped);

  return (
    <Col
      onClick={flipCard}
      className="d-inline-block"
      xs={12}
      sm={12}
      md={6}
      lg={4}
    >
      <ReactCardFlip isFlipped={flipped} flipDirection="horizontal">
        <JobCardFront {...props} />
        <JobCardBack {...props} />
      </ReactCardFlip>
    </Col>
  );
}
