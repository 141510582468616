import "./Jobs.css";
import JobCard from "./JobCard";
import jobList from "./jobList";

export default function Jobs() {
  return (
    <div>
      {jobList.map((job) => (
        <JobCard {...job} key={job.company} />
      ))}
    </div>
  );
}
