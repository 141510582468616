import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JobCardRow from "./JobCardRow";

export default function JobCardBack(props) {
  // Wrapped jobcards in divs because otherwise the flip animation was messed up
  const { company, logo, position, period, techstack } = props;
  return (
    <div>
      <Card className="m-4 text-center" bg="light" text="dark">
        <Card.Header>
          <Card.Title>
            {position}
            <FontAwesomeIcon
              className="position-absolute top-0 start-0 p-2"
              icon="fa-solid fa-arrow-right-long"
              size="sm"
              flip="horizontal"
            />
            <FontAwesomeIcon
              className="position-absolute top-0 end-0 p-2"
              icon="fa-solid fa-arrow-right-long"
              size="sm"
            />
          </Card.Title>
          <Card.Subtitle>
            <Card.Img
              src={logo}
              alt={`${company} logo`}
              style={{ width: "10%", height: "10%" }}
              className="border border-dark rounded-circle"
            />
          </Card.Subtitle>
        </Card.Header>
        <Card.Body>
          {/* Todo: Pass in label to job card row */}
          {/* <JobCardRow />
          <JobCardRow /> */}
          <JobCardRow title="Tech Stack" icons={techstack} />
        </Card.Body>
        <Card.Footer>{period}</Card.Footer>
      </Card>
    </div>
  );
}
